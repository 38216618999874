import _ from 'lodash';

function parsePois (pois) {
  pois = _.isString(pois) ? JSON.parse(pois) : pois;
  return _.map(pois, poi => {
    return {
      id: poi.geofence_id,
      title: poi.geofence_name,
      type: poi.cod_type,
      use: poi.cod_use,
      local_id: poi.local_id,
      position: _.has(poi, 'gmaps') ? poi.gmaps : null,
      radius: _.has(poi, 'radius') ? poi.radius : null,
      geojson: _.has(poi, 'geojson') ? poi.geojson : null,
      url: _.has(poi, 'geojson_url') ? poi.geojson_url : null,
      city: _.has(poi, 'city') ? poi.city : {
        id: poi.local_id,
        title: _.has(poi, 'city') ? poi.city : 'Rio de Janeiro'
      }
    }
  })
}

function list (token, campaign, active, resolve, reject, complete) {
  console.log('ad.list ...', campaign);

  this.$api.SSP.get(`/campaigns/${campaign}/ads/?active=${_.join(active, ',')}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(response => {
    console.log('ad.list => ', response);
    let items = {};
    for (const ad of response.data) {
      const location = {
        geofences: ad.locals,
        pois: this.parsePois(ad.geofences)
      }

      items[ad.ad_id] = {
        id: ad.ad_id,
        title: ad.name,
        media: ad.media,
        location,
        period: {
          start: this.$moment.utc(ad.start_date).local().format('YYYY-MM-DD').toString(),
          end: this.$moment.utc(ad.end_date).local().format('YYYY-MM-DD').toString(),
        },
        recurrence: {
          format: ad.recurrence.format,
          regex: ad.recurrence.reg_exp,
        },
        temperature: ad.temperature==null ? '' : ad.temperature,
        vehicles: ad.vehicles,
        active: ad.active,
        edition: _.has(ad, 'edition') ? {
          title: ad.edition.name,
          media: ad.edition.media,
          location: {
            geofences: ad.edition.locals,
            pois: this.parsePois(ad.edition.geofences)
          },
          period: {
            start: this.$moment.utc(ad.edition.start_date).local().format('YYYY-MM-DD').toString(),
            end: this.$moment.utc(ad.edition.end_date).local().format('YYYY-MM-DD').toString(),
          },
          recurrence: {
            format: ad.edition.recurrence.format,
            regex: ad.edition.recurrence.reg_exp,
          },
          temperature: ad.edition.temperature==null ? '' : ad.temperature,
          vehicles: ad.edition.vehicles,
        } : null
      };
    }
    resolve(campaign, items);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout('ads list');
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
};

function create (token, campaign, data, resolve, reject, complete) {
  console.log('ad.create ...', campaign, data);

  this.$api.SSP.post(`/campaigns/${campaign}/ads/`, new URLSearchParams(data), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('ad.create => ', response);

    const ad = response.data;
    data = {
      id: ad.ad_id,
      title: ad.name,
      media: ad.media,
      location: {
        geofences: ad.locals,
        pois: this.parsePois(ad.geofences)
      },
      period: {
        start: this.$moment.utc(ad.start_date).local().format('YYYY-MM-DD').toString(),
        end: this.$moment.utc(ad.end_date).local().format('YYYY-MM-DD').toString(),
      },
      recurrence: {
        format: ad.recurrence.format,
        regex: ad.recurrence.reg_exp,
      },
      temperature: ad.temperature==null ? '' : ad.temperature,
      vehicles: ad.vehicles,
      active: ad.active
    };

    resolve(campaign, data.id, data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function update (token, campaign, ad, data, resolve, reject, complete) {
  console.log('ad.update ...', campaign, data);

  this.$api.SSP.put(`/campaigns/${campaign}/ads/${ad}/`, new URLSearchParams(data), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then(response => {
    console.log('ad.update => ', response);

    const ad = response.data;
    data = {
      id: ad.ad_id,
      title: ad.name,
      media: ad.media,
      location: {
        geofences: ad.locals,
        pois: this.parsePois(ad.geofences)
      },
      period: {
        start: this.$moment.utc(ad.start_date).local().format('YYYY-MM-DD').toString(),
        end: this.$moment.utc(ad.end_date).local().format('YYYY-MM-DD').toString(),
      },
      recurrence: {
        format: ad.recurrence.format,
        regex: ad.recurrence.reg_exp,
      },
      temperature: ad.temperature==null ? '' : ad.temperature,
      vehicles: ad.vehicles,
      active: ad.active,
      edition: _.has(ad, 'edition') ? {
        title: ad.edition.name,
        media: ad.edition.media,
        location: {
          geofences: ad.edition.locals,
          pois: this.parsePois(ad.edition.geofences)
        },
        period: {
          start: this.$moment.utc(ad.edition.start_date).local().format('YYYY-MM-DD').toString(),
          end: this.$moment.utc(ad.edition.end_date).local().format('YYYY-MM-DD').toString(),
        },
        recurrence: {
          format: ad.edition.recurrence.format,
          regex: ad.edition.recurrence.reg_exp,
        },
        temperature: ad.edition.temperature==null ? '' : ad.temperature,
        vehicles: ad.edition.vehicles,
      } : null,
    };

    resolve(campaign, data.id, data, ad.approval);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function activate (token, campaign, ads, toggle, resolve, reject, complete) {
  console.log('ad.activate ...', campaign, ads);

  this.$api.SSP.post(`/campaigns/${campaign}/ads/activate/`, new URLSearchParams({ ads, toggle }), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then(response => {
    console.log('ad.activate => ', response);

    resolve(campaign, ads);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function deactivate (token, campaign, ads, resolve, reject, complete) {
  console.log('ad.deactivate ...', campaign, ads);

  this.$api.SSP.post(`/campaigns/${campaign}/ads/inactivate/`, new URLSearchParams({ ads }), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then(response => {
    console.log('ad.deactivate => ', response);

    resolve(campaign, ads);

  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function remove (token, campaign, ad, resolve, reject, complete) {
  console.log('ad.remove ...', campaign, ad);

  this.$api.SSP.delete(`/campaigns/${campaign}/ads/${ad}/`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
  .then(response => {
    console.log('ad.remove => ', response);

    resolve(campaign, ad);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function validate (token, media, resolve, reject, complete) {
  console.log('ad.validate ...', media);
  const data = {
    media
  }

  this.$api.SSP.post(`/media/validate/`, new URLSearchParams(data), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then(response => {
    console.log('ad.validate => ', response);

    resolve(response.data.info);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true); 
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

export {
  list,
  create,
  update,
  activate,
  deactivate,
  remove,
  parsePois,
  validate
}

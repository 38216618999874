import _ from 'lodash';

function parseCities (cities, title) {
  if (_.isString(cities)) {
    // console.log(cities, title)
    cities = JSON.parse(cities);
  }
  return cities==null ? null : _.keyBy(_.map(cities, city => {
    return { 
      id: city.id_cidade, 
      weight: city.dec_perc,
      title: _.has(city, 'title') ? city.title : null,
      locked: _.has(city, 'locked') ? city.locked : false
    }
  }), 'id')
}

function list (token, admin, resolve, reject, complete) {
  console.log('campaigns.list ...');

  this.$api.SSP.get('/campaigns/', {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  .then(response => {
    console.log('campaigns.list => ', response);
    let data = response.data;
    let items = {};
    for (let i in data) {
      let campaign = data[i];
      // if (_.has(campaign, 'edition')&&!_.isNil(campaign.edition.cities)) {
      //   console.log('campaign', JSON.parse(campaign.edition.cities));
      // }
      items[campaign.campaign_id] = {
        id: campaign.campaign_id,
        brand: {
          id: campaign.brand_id,
          advertiser: campaign.advertiser_id,
        },
        title: campaign.name,
        impressions: campaign.impressions,
        budget: campaign.budget,
        contract: campaign.contract_id,
        discount: campaign.discount,
        paid: campaign.paid==null ? 2 : campaign.paid,
        visible: campaign.visible==null ? 1 : campaign.visible,
        active: campaign.active, 
        approval: campaign.approval,
        message: _.has(campaign, 'publication_message') ? campaign.publication_message : null,
        period: {
          start: this.$moment.utc(campaign.start_date).local().format('YYYY-MM-DD').toString(),
          end: this.$moment.utc(campaign.end_date).local().format('YYYY-MM-DD').toString(),
        },
        edition: _.has(campaign, 'edition') ? {
          title: campaign.edition.name,
          impressions: campaign.edition.impressions,
          budget: campaign.edition.budget,
          period: {
            start: this.$moment.utc(campaign.edition.start_date).local().format('YYYY-MM-DD').toString(),
            end: this.$moment.utc(campaign.edition.end_date).local().format('YYYY-MM-DD').toString(),
          },
          paid: campaign.edition.paid,
          pois: campaign.edition.pois==null ? [] : _.without(_.map(campaign.edition.pois, p => {
            const id = p.match(/0_6_(.*?)_3/);
            return id!=null ? id[0] : null;
          }), null),
          visible: campaign.edition.visible==null ? 1 : campaign.edition.visible,
          cities: this.parseCities(campaign.edition.cities, campaign.name)
        } : null,
        duration: this.$moment(this.$moment(campaign.end_date).startOf('day').add(1,'d')).diff(this.$moment(campaign.start_date).startOf('day'), 'days'),
        airing: this.isAiring(campaign.active==1, this.$moment.utc(campaign.start_date).local().format('YYYY-MM-DD').toString(), this.$moment.utc(campaign.end_date).local().format('YYYY-MM-DD').toString()),
        children: !admin ? _.filter(campaign.ad_groups, id => {
          const child = _.find(data, ['campaign_id', id]);
          return child != undefined && child.visible == 1;
        }) : campaign.ad_groups,
        bonus: campaign.ad_groups.length>0,
        pois: campaign.pois==null ? [] : _.without(_.map(campaign.pois, p => {
          const id = p.match(/0_6_(.*?)_3/);
          return id!=null ? id[0] : null;
        }), null),
        ads: {},
        cities: this.parseCities(campaign.cities, campaign.name)
      };
    }
    resolve(items);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && error.response.status==401){
      this.getout('campaign list');
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function approve (token, campaign, approved, message, resolve, reject, complete) {
  message = _.isNil(message) ? '' : message;
  console.log('campaigns.approve ...', campaign, approved, message);

  this.$api.SSP.post(`/campaigns/${campaign}/approval/`, 
    new URLSearchParams({
      approved,
      message
    }), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then(response => {
    console.log('campaigns.approve => ', response);
    const data = response.data;

    if (approved==1&&_.has(data, 'ads')) {
      data.ads = _.mapValues(_.keyBy(data.ads, 'ad_id'), ad => {
        return ad.active;
      });
    }

    resolve(campaign, data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function activate (token, campaign, resolve, reject, complete) {
  console.log('campaigns.activate ...', campaign);

  this.$api.SSP.post(`/campaigns/${campaign}/activate/`, '', {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then(response => {
    console.log('campaigns.activate => ', response);
    resolve(campaign);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function publish (token, campaign, resolve, reject, complete) {
  console.log('campaigns.publish ...', campaign);

  this.$api.SSP.post(`/campaigns/${campaign}/publish/`, '', {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then(response => {
    console.log('campaigns.publish => ', response);
    resolve(campaign, response.data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function deactivate (token, campaign, resolve, reject, complete) {
  console.log('campaigns.deactivate ...', campaign);

  this.$api.SSP.post(`/campaigns/${campaign}/inactivate/`, '', {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then(response => {
    console.log('campaigns.deactivate => ', response);
    resolve(campaign);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function remove (token, campaign, resolve, reject, complete) {
  console.log('campaigns.remove ...', campaign);

  this.$api.SSP.delete(`/campaigns/${campaign}/`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
  .then(response => {
    console.log('campaigns.remove => ', response);
    resolve(campaign);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function create (token, data, admin, resolve, reject, complete) {
  console.log('campaign.create ...', data);

  this.$api.SSP.post(`/campaigns/`, new URLSearchParams(data), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('campaign.create => ', response);
    const campaign = response.data[0];
    data = {
      id: campaign.campaign_id,
      brand: {
        id: campaign.brand_id,
        advertiser: campaign.advertiser_id,
      },
      contract: campaign.contract_id,
      title: campaign.name,
      impressions: campaign.impressions,
      budget: campaign.budget,
      discount: campaign.discount,
      paid: campaign.paid,
      visible: campaign.visible==null ? 1 : campaign.visible,
      active: campaign.active, 
      approval: campaign.approval,
      period: {
        start: this.$moment.utc(campaign.start_date).local().format('YYYY-MM-DD').toString(),
        end: this.$moment.utc(campaign.end_date).local().format('YYYY-MM-DD').toString(),
      },
      duration: this.$moment(this.$moment(campaign.end_date).startOf('day').add(1,'d')).diff(this.$moment(campaign.start_date).startOf('day'), 'days'),
      airing: this.isAiring(campaign.active==1, campaign.start_date, campaign.end_date),
      children: !admin ? _.filter(campaign.ad_groups, id => {
        const child = _.find(data, ['campaign_id', id]);
        return child != undefined && child.visible == 1;
      }) : campaign.ad_groups,
      bonus: campaign.ad_groups.length>0,
      pois: _.without(_.map(campaign.pois, p => {
        const id = p.match(/0_6_(.*?)_3/);
        return id!=null ? id[0] : null;
      }), null),
      ads: {},
      edition: null,
      cities: this.parseCities(campaign.cities)
    };
    resolve(data.id, data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}

function update (token, campaign, data, admin, resolve, reject, complete) {
  console.log('campaign.update ...', campaign, data);

  this.$api.SSP.put(`/campaigns/${campaign}/`, new URLSearchParams(data), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('campaign.update => ', response);
    const campaign = response.data[0];
    data = {
      id: campaign.campaign_id,
      brand: {
        id: campaign.brand_id,
        advertiser: campaign.advertiser_id,
      },
      title: campaign.name,
      impressions: campaign.impressions,
      budget: campaign.budget,
      contract: campaign.contract_id,
      discount: campaign.discount,
      paid: campaign.paid,
      visible: campaign.visible==null ? 1 : campaign.visible,
      active: campaign.active, 
      approval: campaign.approval,
      period: {
        start: this.$moment.utc(campaign.start_date).local().format('YYYY-MM-DD').toString(),
        end: this.$moment.utc(campaign.end_date).local().format('YYYY-MM-DD').toString(),
      },
      duration: this.$moment(this.$moment(campaign.end_date).startOf('day').add(1,'d')).diff(this.$moment(campaign.start_date).startOf('day'), 'days'),
      airing: this.isAiring(campaign.active==1, campaign.start_date, campaign.end_date),
      children: !admin ? _.filter(campaign.ad_groups, id => {
        const child = _.find(data, ['campaign_id', id]);
        return child != undefined && child.visible == 1;
      }) : campaign.ad_groups,
      bonus: campaign.ad_groups.length>0,
      pois: _.without(_.map(campaign.pois, p => {
        const id = p.match(/0_6_(.*?)_3/);
        return id!=null ? id[0] : null;
      }), null),
      ads: {},
      edition: _.has(campaign, 'edition') ? {
        title: campaign.edition.name,
        impressions: campaign.edition.impressions,
        budget: campaign.edition.budget,
        period: {
          start: this.$moment.utc(campaign.edition.start_date).local().format('YYYY-MM-DD').toString(),
          end: this.$moment.utc(campaign.edition.end_date).local().format('YYYY-MM-DD').toString(),
        },
        paid: campaign.edition.paid,
        pois: campaign.edition.pois==null ? [] : _.without(_.map(campaign.edition.pois, p => {
          const id = p.match(/0_6_(.*?)_3/);
          return id!=null ? id[0] : null;
        }), null),
        visible: campaign.edition.visible==null ? 1 : campaign.edition.visible,
        cities: this.parseCities(campaign.edition.cities)
      } : null,
      cities: this.parseCities(campaign.cities)
    };
    resolve(data.id, data);
  })
  .catch(error => {
    if(_.has(error.response, 'status') && (error.response.status==401)){
      this.getout();
      this.handleError(error, 'Sua sessão expirou...', true);
    }else{
      this.handleError(error);
      if (this.isValid(reject)) reject(error);
    }
  })
  .finally(() => {
    if (this.isValid(complete)) complete();
  });
}


export {
  list,
  approve,
  activate,
  deactivate,
  create,
  update,
  publish,
  remove,
  parseCities
}

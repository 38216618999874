<template>
  <v-container 
    fluid
    class="campaigns"
    @dragenter="toggleUpload(true)"
    @dragleave="toggleUpload(false)"
  >
    <!-- @dragover.prevent="toggleUpload(true)" -->
    <div class="filters d-flex align-center justify-start mb-4">
      <!-- status selection -->
      <v-btn-toggle
        v-model="filters.status.value"
        active-class="active primary primary--text"
        :mandatory="updated"
        class="status-filter mr-4"
      >
        <v-btn
          v-for="option in statuses"
          :key="`status-filter-option-${option.value}`"
          :value="option.value"
          outlined
          :class="{ 'text--disabled': option.count==0 }"
          class="option button px-4 text-overline"
          @click="filters.status.value = option.value"
        >
          {{ option.text }}
          <v-badge
            v-if="option.count"
            inline
            :color="filters.status.value==option.value ? 'primary' : 'grey lighten-1'"
            :content="option.count"
            class="small"
          />
        </v-btn>
      </v-btn-toggle>
      <v-btn
        text
        plain
        :disabled="!hasContracts"
        color="accent"
        @click.stop="setNewCampaign"
      >
        <v-icon left>
          {{ icons.add }}
        </v-icon>
        {{ $t('general.create_thing', { thing: $tc('general.campaign') }) }}
      </v-btn>
      <v-btn
        text
        plain
        color="primary"
        @click.stop="toggleUpload(true, true)"
      >
        <v-icon left>
          {{ icons.videoCheck }}
        </v-icon>
        {{ $t('general.test_thing', { thing: $tc('general.media') }) }}
      </v-btn>
      

      <v-spacer />

      <!-- search box 
      <v-text-field
        v-model="filters.search.value"
        :loading="filters.search.loading"
        :disabled="filters.search.disabled"
        :error-messages="filters.search.messages"
        :prepend-inner-icon="icons.search"
        solo
        flat
        :placeholder="$t(filters.search.label)"
        clearable
        hide-details
        :color="filters.search.focus ? 'primary' : undefined"
        @blur="filters.search.value==null||filters.search.value=='' ? filters.search.focus=false : filters.search.focus=true"
        @focus="filters.search.focus=true"
        @keyup.esc="filters.search.value=null;"
        :style="{ 'width': filters.search.focus ? '240px' : '36px' }"
        style="transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1) width;"
        class="flex-grow-0 transparent"
      />-->
    </div>

    <div 
      v-if="filters.status.value=='new'"
      class="new-campaign"
    >
      <!-- new campaign card -->
      <!-- <campaign-card 
        :id="'c.id'"
        class="mb-4"
      /> -->
    </div>
    <div 
      v-else
      class="list"
    >
      <div 
        v-if="list.length>0"
        class="list-content"
      >
        <!-- campaigns -->
        <campaign-card 
          v-for="campaign in list"
          :key="`campaign-card-${campaign.id}`"
          :ref="`campaign-${campaign.id}`"
          :id="campaign.id"
          :campaign="campaign"
          :campaigns="byBrand"
          :contracts="contracts"
          :loading="loader[campaign.id].loading"
          :updated="loader[campaign.id].updated"
          :refresh="loader[campaign.id].refresh"
          :archived="loader[campaign.id].archived"
          :status="loader[campaign.id].ads"
          :cities="cities"
          :analytics="analytics[campaign.id]"
          :show-map="showMap"
          :user="user"
          :admin="admin"
          :autoplay="!toggleView"
          class="mb-4"
          @select="selectCampaign"
          @remove="removeCampaign"
          @activate="activateCampaign"
          @approve="approveCampaign"
          @publish="publishCampaign"
          @get-ads="loadAds"
          @activate-ad="activateAd"
          @select-ad="selectAd"
          @new-ad="setNewAd"
          @get-analytics="loadCampaignAnalytics"
          @open-dashboard="openDashboard"
        >
          <template v-slot:groups>
            <campaign-card 
              v-for="group in campaign.children"
              :key="`group-card-${group}`"
              :ref="`campaign-${group}`"
              :id="group"
              :campaign="campaigns[group]"
              :campaigns="byBrand"
              :contracts="contracts"
              :loading="loader[group].loading"
              :updated="loader[group].updated"
              :refresh="loader[group].refresh"
              :archived="loader[group].archived"
              :status="loader[group].ads"
              :cities="cities"
              :grouped-in="groupedIn(group)"
              :analytics="analytics[group]"
              :show-map="showMap"
              :user="user"
              :admin="admin"
              :autoplay="!toggleView"
              class="group mb-4"
              @select="selectCampaign"
              @remove="removeCampaign"
              @activate="activateCampaign"
              @approve="approveCampaign"
              @publish="publishCampaign"
              @get-ads="loadAds"
              @activate-ad="activateAd"
              @select-ad="selectAd"
              @new-ad="setNewAd"
              @get-analytics="loadCampaignAnalytics"
              @open-dashboard="openDashboard"
            />
          </template>
        </campaign-card>
      </div>
      <!-- <div 
        v-else
        class="list-loading d-flex flex-column align-center justify-center"
      >
        <loading class="loading mb-16" />

      </div> -->
    </div>

    <v-bottom-sheet 
      v-model="toggleView"
      transition="slide-y-reverse-transition"
      content-class="campaign-dialog"
    >
      <campaign-card 
        v-if="toggleView"
        :ref="`campaign-view`"
        :id="view.campaign"
        :ad="view.ad"
        :campaign="campaigns[view.campaign]"
        :brand="brandData"
        :contracts="contracts"
        :campaigns="byBrand"
        :loading="loader[view.campaign].loading"
        :updated="loader[view.campaign].updated"
        :refresh="loader[view.campaign].refresh"
        :archived="loader[view.campaign].archived"
        :status="loader[view.campaign].ads"
        :cities="cities"
        :grouped-in="groupedIn(view.campaign)"
        :analytics="analytics[view.campaign]"
        :admin="admin"
        :editing="editingCampaign"
        :user="user"
        :closeable="true"
        height="100%"
        content-class="campaign-dialog"
        @message="toggleMessage"
        @select="selectCampaign"
        @remove="removeCampaign"
        @activate="activateCampaign"
        @publish="publishCampaign"
        @approve="approveCampaign"
        @update="updateCampaign"
        @create="createCampaign"
        @get-ads="loadAds"
        @get-analytics="loadCampaignAnalytics"
        @activate-ad="activateAd"
        @select-ad="selectAd"
        @update-ad="updateAd"
        @create-ad="createAd"
        @remove-ad="removeAd"
        @new-ad="setNewAd"
        @open-dashboard="openDashboard"
        @close="closeCampaignView"
      />
    </v-bottom-sheet>

    <v-overlay
      v-model="view.tester.toggle"
      fixed
      color="white"
      opacity="0.96"
    >
      <media-upload 
        test
        :validation="view.tester.validation"
        @toggle="(toggle) => toggleUpload(toggle, toggle)"
        @validate="validateMedia"
      />
    </v-overlay>

    <v-overlay 
      :value="!updated"
      color="white"
      absolute
      opacity=".8"
      class="loading-overlay text-center"
    >
      <loading class="loading mb-4" />
      <span class="d-block text-overline grey--text text--lighten-1">
        {{ $t('general.loading') }}
      </span>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">

  .campaigns .filters .status-filter .button.option.active {
    border-bottom-color: var(--primary) !important;
    font-weight: 900;
    &:before {
      opacity: .04;
    }
  }
  .campaigns .filters .status-filter .option {
    border-bottom: 2px solid !important;
    background: transparent !important;
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: inherit !important;
  }

  .list-loading {
    min-height: calc(100vh - 120px);
  }

  .status-loading {
    opacity: .8;
  }

  .loading-overlay .loading {
    width: 48px;
    height: 48px;
    opacity: .8;
  }

  .campaigns .skeleton:nth-of-type(1) {
    opacity: .6;
  }
  .campaigns .skeleton:nth-of-type(2) {
    opacity: .4;
  }
  .campaigns .skeleton:nth-of-type(3) {
    opacity: .2;
  }

  .campaign-dialog {
    height: calc(100vh - 40px) !important;
    max-height: calc(100vh - 40px) !important;
  }

</style>

<script>
  // Icons
  import { mdiMagnify, mdiPlus, mdiMovieCheckOutline } from '@mdi/js';

  // Utilities
  import services from '@/services'
  import { list as ApiGetCampaigns, activate as ApiActivateCampaign, deactivate as ApiDeactivateCampaign, remove as ApiRemoveCampaign, create as ApiCreateCampaign, update as ApiUpdateCampaign, publish as ApiPublishCampaign, approve as ApiApproveCampaign, parseCities } from '@/api/campaigns'
  import { list as ApiGetAds, create as ApiCreateAd, update as ApiUpdateAd, activate as ApiActivateAd, remove as ApiRemoveAd, parsePois, validate as ApiValidateMedia } from '@/api/ads'
  import { customs as ApiGetCustoms } from '@/api/geofences'
  import { overview as ApiGetAnalytics } from '@/api/dashboard';
  import CityIcons from '@/assets/cities/icons';
  import { sync } from 'vuex-pathify'
  import _ from 'lodash';

  export default {
    name: 'Campaigns',
    metaInfo () {
      const title = this.getDictionary('campaigns');
      return {
        title,
      }
    },

    components: {
      CampaignCard: () => import('@/views/campaigns/CampaignCard'),
      Loading: () => import('@/components/IconLoading'),
      MediaUpload: () => import('@/components/campaigns/MediaUpload'),
    },

    props: {
      admin: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      icons: {
        search: mdiMagnify,
        add: mdiPlus,
        videoCheck: mdiMovieCheckOutline,
      },
      filters: {
        search: {
          value: '',
          focus: false,
          loading: false,
          disabled: false,
          label: 'general.search',
          messages: []
        },
        status: {
          value: 'airing',
          suggested: false,
          loading: false,
          options: {
            airing: {
              text: 'campaigns.status.airing',
              value: 'airing',
              toggle: false,
              count: 0,
            },
            scheduled: {
              text: 'campaigns.status.scheduled',
              value: 'scheduled',
              toggle: false,
              count: 0,
            },
            paused: {
              text: 'campaigns.status.paused',
              value: 'paused',
              toggle: false,
              count: 0,
            },
            created: {
              text: 'campaigns.status.created',
              value: 'created',
              toggle: false,
              count: 0,
            },
            completed: {
              text: 'campaigns.status.completed',
              value: 'completed',
              toggle: false,
              count: 0,
            },
          }
        }
      },
      view: {
        ad: null,
        campaign: null,
        tester: {
          toggle: false,
          hover: false,
          validation: {
            success: null,
            message: null,
            loading: false
          }
        },
      },
      loader: {
        error: 0
      },
      analytics: {},
      loading: false,
      updated: false,
    }),

    computed: {
      campaigns: sync('campaigns/items'),
      brands: sync('clients/items'),
      brand: sync('app/views@brand.selected'),
      supported: sync('app/views@map.cities'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      list () {
        const filter = this.filters.status.value;
        const list = this[filter];
        const items = this.searchList(list);
        console.log('list', items);
        return _.orderBy(items, [c => c.approval==null ? 1.5 : c.approval, 'period.start', 'period.end'], ['asc', 'desc', 'desc']);
      },
      statuses () {
        return this.translate(_.filter(this.filters.status.options, ['toggle', true]));
      },
      airing () {
        const list = _.reduce(this.groups, (l, campaign) => {
          if (_.isEmpty(campaign.children)&&campaign.airing&&campaign.active) {
            l.push(campaign);
          }else{
            let g = _.clone(campaign);
            g.children = _.filter(g.children, child => this.campaigns[child].airing);
            if (!_.isEmpty(g.children)) l.push(g);
          }
          return l;
        }, []);
        return list;
      },
      paused () {
        const list = _.reduce(this.groups, (l, campaign) => {
          if (_.isEmpty(campaign.children)&&(campaign.active==0&&this.$moment().isSameOrBefore(campaign.period.end))) {
            l.push(campaign);
          }else{
            let g = _.clone(campaign);
            g.children = _.filter(g.children, child => this.campaigns[child].active==0 && this.$moment().isSameOrBefore(this.campaigns[child].period.end));
            if (!_.isEmpty(g.children)) l.push(g);
          }
          return l;
        }, []);
        return list;
      },
      created () {
        const list = _.reduce(this.groups, (l, campaign) => {
          if (_.isEmpty(campaign.children)&&campaign.active==2) {
            l.push(campaign);
          }else{
            let g = _.clone(campaign);
            g.children = _.filter(g.children, child => this.campaigns[child].active==2);
            if (!_.isEmpty(g.children)) l.push(g);
          }
          return l;
        }, []);
        return list;
      },
      scheduled () {
        const list = _.reduce(this.groups, (l, campaign) => {
          if (_.isEmpty(campaign.children)&&(campaign.active==1&&this.$moment().isBefore(campaign.period.start))) {
            l.push(campaign);
          }else{
            let g = _.clone(campaign);
            g.children = _.filter(g.children, child => this.campaigns[child].active==1&&this.$moment().isBefore(this.campaigns[child].period.start));
            if (!_.isEmpty(g.children)) l.push(g);
          }
          return l;
        }, []);
        return list;
      },
      completed () {
        const campaigns = this.campaigns;
        const list = _.reduce(this.groups, (l, campaign) => {
          if (_.isEmpty(campaign.children)&&(campaign.active!=2 && this.$moment().endOf('day').isAfter(campaign.period.end))) {
            l.push(campaign);
          }else{
            let g = _.clone(campaign);
            g.children = _.filter(g.children, child => {
              const campaign = campaigns[child];
              return campaign.active!=2 && this.$moment(campaign.period.end).endOf('day').isBefore(this.$moment().endOf('day'))
            });
            if (!_.isEmpty(g.children)) l.push(g);
          }
          return l;
        }, []);
        return list;
      },

      brandData () {
        const brands = this.brands;
        const selected = this.brand;
        return _.has(brands, selected) ? brands[selected] : null;
      },

      byBrand () {
        const brand = this.brand;
        const campaigns = _.filter(this.campaigns, c => {
          return c.brand.id==parseInt(brand) && (this.admin || c.visible==1);
        });
        return campaigns;
      },

      cities () {
        return _.isNil(this.brandData) ? {} : _.pick(this.supported, this.brandData.cities);
      },

      contracts () {
        const brand = !_.isNil(this.brand) && _.has(this.brands, this.brand) ? this.brands[this.brand] : null;
        const contracts = !_.isNil(brand) && _.has(brand, 'contracts') ? brand.contracts : {};
        const campaigns = _.clone(_.filter(this.byBrand, c => c.children.length==0));
        return _.mapValues(_.clone(contracts), contract => {
          let advertiser = _.find(brand.advertisers, ['id', contract.advertiser]);
          advertiser = _.isNil(advertiser.agency) ? brand.title : advertiser.agency.name;
          let provisioned = _.sumBy(_.filter(campaigns, ['contract', contract.id]), 'budget');
          if (provisioned>contract.budget) provisioned = contract.budget;
          console.log(contract.id, provisioned);
          return {
            ...contract,
            provisioned,
            advertiser,
          }
        });
      },

      hasContracts () {
        return _.size(_.filter(this.contracts, contract => this.$moment().isBefore(contract.period.end)))>0
      },

      groups () {
        const children = _.flatten(_.map(_.filter(this.byBrand, c => !_.isEmpty(c.children)), d => d.children));
        const groups = _.filter(this.byBrand, c => {
          const list = _.indexOf(children, c.id) < 0;
          // if (c.children.length) console.log(c.children);
          return list;
        });
        return groups;
      },

      editingCampaign () {
        return this.$route.name=='Campanha';
      },

      showMap () {
        return _.size(this.list)<=1&&_.every(this.list, campaign => _.size(campaign.children)<=1);
      },

      toggleView: {
        get () {
          const campaigns = this.campaigns;
          const view = this.view;
          return !_.isNil(view.campaign)&&_.has(campaigns, view.campaign)||!_.isNil(view.campaign)&&!_.isNil(view.ad)&&_.has(campaigns, view.campaign)&&_.has(campaigns[view.campaign].ads, view.ad);
        },
        set (b) {
          if (b) {
            // none
          }else{
            if (this.$route.name!='Campanhas') this.closeCampaignView();
            // const params = _.omit(this.$route.params, ['campaign', 'ad']);
            // setTimeout(() => {
            //   this.$router.push({
            //     name: 'Campanhas',
            //     params
            //   }).catch((error) => {
            //     console.warn(error);
            //   });
            // }, 250);
          }
        }
      },
    },

    watch: {
      'filters.status.value' (status, old) {
        if (status=='new') {
          setTimeout(() => this.filters.status.value = 'created', 250);
        }
      },
      campaigns: {
        immediate: true,
        deep: true,
        handler (campaigns) {
          // build loader structure
          console.log('loader struct');
          _.each(campaigns, campaign => {
            if (!_.has(this.analytics, campaign.id)) {
              this.$set(this.analytics, campaign.id, {
                impressions: null,
                error: 0
              });
            }
            if (!_.has(this.loader, campaign.id)) {
              // console.log('loader struct ads', campaign.ads);
              this.$set(this.loader, campaign.id, {
                ads: _.mapValues(campaign.ads, ad => {
                  return {
                    loading: false,
                    updated: ad.id=='new',
                    error: 0
                  }
                }),
                refresh: false,
                loading: false,
                analytics: false,
                updated: campaign.id=='new',
                archived: false,
                error: 0
              });
            }
          });
        }
      },
      list: {
        deep: true,
        handler (campaigns) {
          this.$nextTick(() => {
            if (this.updated&&_.size(this.byBrand)==0){
              // this.setNewCampaign();
            }else if (!this.filters.status.suggested||_.size(campaigns)==0) {
              this.filters.status.value = this.suggestStatus();
            }
          });
        }
      },
      brand (id) {
        console.log('brand updated', id);
        this.getCustoms(this.brand);
        this.updateView(true);
      },
      $route (to, from) {
        console.log('route updated');
        this.updateView(false, to);
      },
      airing: {
        immediate: true,
        handler (v) {
          const status = this.filters.status.options.airing;
          status.count = v.length;
          status.toggle = status.count>0;
        }
      },
      paused: {
        immediate: true,
        handler (v) {
          const status = this.filters.status.options.paused;
          status.count = v.length;
          status.toggle = status.count>0;
        }
      },
      created: {
        immediate: true,
        handler (v) {
          const status = this.filters.status.options.created;
          status.count = v.length;
          status.toggle = status.count>0;
        }
      },
      scheduled: {
        immediate: true,
        handler (v) {
          const status = this.filters.status.options.scheduled;
          status.count = v.length;
          status.toggle = status.count>0;
        }
      },
      completed: {
        immediate: true,
        handler (v) {
          const status = this.filters.status.options.completed;
          status.count = v.length;
          status.toggle = status.count>0;
        }
      },
    },

    methods: {
      ...services,
      ApiGetCampaigns,
      ApiActivateCampaign,
      ApiDeactivateCampaign,
      ApiRemoveCampaign,
      ApiCreateCampaign,
      ApiUpdateCampaign,
      ApiPublishCampaign,
      ApiApproveCampaign,
      parseCities,
      ApiGetAds,
      ApiCreateAd,
      ApiUpdateAd,
      ApiGetAnalytics,
      ApiActivateAd,
      ApiRemoveAd,
      ApiGetCustoms,
      parsePois,
      ApiValidateMedia,

      toggleUpload (b, opened) {
        // console.log('toggle upload', b);
        this.view.tester.opened = opened;
        if (b||b==opened) this.view.tester.toggle = b;
        setTimeout(($, b, opened) => {
          const controller = $.view.tester;
          if (!_.isNil(opened)) controller.toggle = b&&controller.opened;
          if (!controller.toggle) console.log('toggle upload confirm', b, opened);
        }, 500, this, b, opened);
      },

      suggestStatus () {
        let status;
        for (const s in this.filters.status.options) {
          const hasCampaign = this.view.campaign!=null && !_.isNil(_.find(this[s], ['id', this.view.campaign]));
          if (hasCampaign||this.searchList(this[s]).length) {
            status = s;
            this.filters.status.suggested = true;
            return _.isNil(status) ? 'new' : status;
          }
        }
        return status;
      },
      
      searchList (list) {
        const k = this.filters.search.value;
        const key = this.isValid(k) ? new RegExp(k, 'gi') : new RegExp('', 'gi');
        const items = _.filter(list, (item) => {
          return k != '' ? key.test(item.title) || key.test(item.brand) : true;
        });
        return items;
      },

      getBrand (id) {
        this.$emit('get-brand', id);
      },

      toggleMessage (message) {
        this.toggleToast(
          true,
          message,
          7000,
          false
        );
      },

      loadCampaigns () {
        this.loading = true;
        this.ApiGetCampaigns(
          this.user.auth.token,
          this.admin,
          (campaigns) => { 
            let del = _.map(this.campaigns, c => c.id);
            _.each(campaigns, campaign => {
              // if (campaign.id==714) console.log('campaign', campaign.id, campaign.paid);
              if (campaign.children.length>0) {
                const [ start, end ] = this.campaignPeriod(campaigns, campaign.id, campaign.children, true);
                campaign.airing = _.some(campaign.children, child => campaigns[child].airing);
                campaign.active = _.some(campaign.children, child => campaigns[child].active==1) ? 1 : 0;
                campaign.visible = _.some(campaign.children, child => campaigns[child].visible==1) ? 1 : 0;
                campaign.period.start = start;
                campaign.period.end = end;
                campaign.budget = _.reduce(campaign.children, (sum, child) => {
                  return sum + campaigns[child].budget;
                }, 0);
                campaign.impressions = _.reduce(campaign.children, (sum, child) => {
                  return sum + campaigns[child].impressions;
                }, 0);
                if (_.has(campaign, 'edition')&&!_.isNil(campaign.edition)) campaign.edition.budget = _.reduce(campaign.children, (sum, child) => {
                  return sum + (campaigns[child].approval==1 ? campaigns[child].budget : campaigns[child].edition.budget);
                }, 0);
                campaign.approval = _.every(campaign.children, child => {
                  return campaigns[child].approval==1;
                }) ? 1 : null;
                campaign.paid = 2;
              }
              const ads = _.has(this.campaigns, campaign.id) ? this.campaigns[campaign.id].ads : {}
              this.$set(this.campaigns, campaign.id, _.assign(campaign, { ads }));
              if (campaign.visible==1||this.admin) del = _.without(del, campaign.id);
            });
            _.each(del, c => this.$delete(this.campaigns, c));

            const brand = parseInt(this.brand);
            const brandCampaigns = _.filter(campaigns, ['brand.id', brand]);

            if (_.size(brandCampaigns)==0&&_.has(this.brands[this.brand], 'contracts')&&this.hasContracts||(this.view.campaign=='new'&&!_.has(this.campaigns, this.view.campaign))) {
              this.setNewCampaign();
              this.updated = true;

              return
            }

            if (this.loader.error>5) this.toggleToast(false);
            this.updated = true;
            this.loader.error = 0;
          },
          (error) => { 
            if (error.response.status != 404) {
              this.loader.error += 1;
              if (this.loader.error>5) {
                this.toggleToast(
                  true,
                  this.$t('messages.waiting_response'),
                  -1,
                  false
                );
              }
              setTimeout(this.loadCampaigns, 15000);
            }
          },
          () => { 
            this.loading = false;
            this.filters.status.value = this.suggestStatus();
          }
        );
      },

      loadAds (params) {
        let { campaign, archived } = params;
        const newCampaign = this.$route.params.campaign=='new' || this.$route.params.ad=='new';
        if (_.isNil(campaign)||newCampaign) return;
        this.loader[campaign].loading = true;
        archived = !_.isNil(archived)&&archived;
        this.ApiGetAds(
          this.user.auth.token,
          campaign,
          archived ? [0] : [1,2],
          (campaign, ads) => {
            let del = _.map(this.campaigns[campaign].ads, a => a.id);
            _.each(ads, ad => {
              this.$set(this.campaigns[campaign].ads, ad.id, ad);
              this.$set(this.loader[campaign].ads, ad.id, {
                loading: false,
                updated: true,
                error: 0
              });
              del = _.without(del, ad.id);
            });

            if (this.loader[campaign].error>5) this.toggleToast(false);
            if (archived) {
              this.loader[campaign].archived = true;
              if (_.size(ads)==0) {
                this.toggleToast(
                  true,
                  this.$t('ads.no_inactive_ads'),
                  7000,
                  false
                );
              }
            }else{
              _.each(del, a => this.$delete(this.campaigns[campaign].ads, a));
              this.loader[campaign].updated = true;
            }
            this.loader[campaign].error = 0;
          },
          (error) => { 
            if (error.response.status != 404) {
              this.loader[campaign].error += 1;
              if (this.loader[campaign].error>5) {
                this.toggleToast(
                  true,
                  this.$t('campaigns.loading_thing', { thing: this.$tc('general.ad', Infinity) })+'...',
                  -1,
                  false
                );
              }
              setTimeout(this.loadAds, 15000, { campaign });
            }
          },
          () => { 
            this.loader[campaign].loading = false;
          }
        )
      },

      loadCampaignAnalytics (campaign) {
        this.loader[campaign].analytics = false;
        this.ApiGetAnalytics(
          this.user.auth.token,
          campaign.toString(),
          campaign, 
          _.filter(this.campaigns[campaign].children, child => {
            return this.campaigns[child].visible;
          }), 
          _.values(this.campaigns[campaign].period),
          (campaign, data) => { 
            const impressions = _.reduce(data, (total, day) => {
              return total + day.impressions;
            }, 0);
            this.$set(this.analytics[campaign], 'impressions', impressions);
            this.loader[campaign].updated = true;
          },
          (error) => { 
            // this.toggleToast(
            //   true,
            //   'Processando analytics da campanha ' + this.campaigns[campaign].title + ' ...',
            //   7000,
            //   false
            // );
            // setTimeout(this.loadCampaignAnalytics, 5000, campaign);
          },
          () => { 
            this.loader[campaign].analytics = true;
          }
        )
      },

      approveCampaign (data) {
        const campaign = data.id;
        const approved = data.approved;
        const message = data.message;
        const title = this.campaigns[campaign].title;

        this.loader[campaign].loading = true;
        this.ApiApproveCampaign(
          this.user.auth.token,
          campaign,
          approved,
          message,
          (campaign, data) => { 
            this.campaigns[campaign].approval = data.approval;
            this.campaigns[campaign].active = data.active;
            let verb = 'rejected';
            if (approved==1) {
              this.campaigns[campaign].airing = true;
              verb = 'approved_published';
              this.loadAds({ campaign });
              // _.each(data.ads, (active, id) => {
              //   this.campaigns[campaign].ads[id].active = active;
              // });
              this.filters.status.value = 'airing'
            }
            this.loader[campaign].refresh = true;

            this.getBrand(this.brand);

            this.toggleToast(
              true,
              this.$t('campaigns.operation_success', { title, verb: this.$t('campaigns.'+verb) }),
              7000,
              false
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('errors.unknown') : 
              _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');

            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.loader[campaign].loading = false;
          }
        )
      },

      publishCampaign (campaign) {
        const title = this.campaigns[campaign].title;

        this.loader[campaign].loading = true;
        this.ApiPublishCampaign(
          this.user.auth.token,
          campaign,
          (campaign, data) => { 
            this.campaigns[campaign].approval = 0;
            const alert = _.has(data, 'alert') ? this.$t('general.attention')+': '+data.alert : ''
            this.toggleToast(
              true,
              this.$t('campaigns.processing_publish', { title })+' '+alert,
              -1,
              true
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('campaigns.publish_failed', { title }) : 
              _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');

            this.toggleToast(
              true,
              msg,
              -1,
              true
            );
          },
          () => { 
            this.loader[campaign].loading = false;
          }
        )
      },

      activateCampaign (campaign) {
        const active = this.campaigns[campaign].active;
        const title = this.campaigns[campaign].title;
        const action = active ? 'ApiDeactivateCampaign' : 'ApiActivateCampaign';

        this.loader[campaign].loading = true;
        const $ = this;
        this[action](
          this.user.auth.token,
          campaign,
          (campaign) => { 
            $.campaigns[campaign].active = active == 0 ? 1 : 0;
            $.campaigns[campaign].airing = $.isAiring(active==0, $.campaigns[campaign].period.start, this.campaigns[campaign].period.end);
            this.toggleToast(
              true,
              this.$t('campaigns.operation_success', { title, verb: this.$t('campaigns.'+(active ? 'paused' : 'activated')) }),
              7000,
              false
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('errors.unknown') : 
              _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');
              
            if (error.response.status==406) {
              $.campaigns[campaign].active = active == 0 ? 1 : 0;
              $.campaigns[campaign].airing = $.isAiring(active==0, $.campaigns[campaign].period.start, this.campaigns[campaign].period.end);
            }

            this.toggleToast(
              true,
              msg,
              -1,
              true
            );
          },
          () => { 
            this.loader[campaign].loading = false;
          }
        )
      },

      removeCampaign (campaign) {
        const title = this.campaigns[campaign].title;

        this.loader[campaign].loading = true;
        this.ApiRemoveCampaign(
          this.user.auth.token,
          campaign,
          (campaign) => { 
            this.closeCampaignView();
            setTimeout(($) => {
              $.toggleToast(
                true,
                this.$t('campaigns.operation_success', { title, verb: this.$t('campaigns.removed') }),
                7000,
                false
              );
              const group = _.find(this.byBrand, campaign => _.indexOf(campaign.children, campaign)>=0);
              if (!_.isNil(group)) this.campaigns[group.id].children = _.without(this.campaigns[group.id].children, campaign);
              $.$delete(this.campaigns, campaign);
            }, 250, this);
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('errors.unknown') : 
              _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');
            
            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.loader[campaign].loading = false;
          }
        )
      },

      createCampaign (data) {
        const campaign = data.id;
        const title = data.title;
        data = { brand_id: this.brand, ...this.processCampaignData(_.omit(data, 'id')) };

        this.loader[campaign].loading = true;
        this.ApiCreateCampaign(
          this.user.auth.token,
          data,
          this.admin,
          (campaign, data) => { 
            // this.closeCampaignView();
            this.$set(this.campaigns, campaign, data);

            this.$nextTick(() => {
              setTimeout(() => {
                this.filters.status.value = 'created';
                if (_.isEmpty(data.children)) this.setNewAd({ campaign });
              }, 500);
            });

            this.toggleToast(
              true,
              this.$t('campaigns.operation_success', { title, verb: this.$t('campaigns.created') }),
              7000,
              false
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('errors.unknown') : 
              _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');

            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.loader[campaign].loading = false;
          }
        )
      },

      updateCampaign (data) {
        const campaign = data.id;
        const title = _.has(data, 'title') ? data.title : this.campaigns[campaign].title;
        data = this.processCampaignData(data);

        this.loader[campaign].loading = true;
        this.ApiUpdateCampaign(
          this.user.auth.token,
          campaign,
          data,
          this.admin,
          (campaign, data) => { 
            const ads = this.campaigns[campaign].ads;
            this.$set(this.campaigns, campaign, _.assign(data, { ads }));
            this.toggleToast(
              true,
              this.$t('campaigns.operation_success', { title, verb: this.$t('campaigns.updated') }),
              7000,
              false
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('errors.unknown') : 
              _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');
            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.loader[campaign].loading = false;
          }
        )
      },

      activateAd (data) {
        const campaign = data.campaign;
        const ad = data.ad;
        const active = this.campaigns[campaign].ads[ad].active;
        const toggle = active == 0 ? 1 : 0;
        const title = this.campaigns[campaign].ads[ad].title;

        this.loader[campaign].ads[ad].loading = true;
        this.ApiActivateAd(
          this.user.auth.token,
          campaign,
          [ad],
          toggle,
          (campaign, ads) => { 
            this.campaigns[campaign].ads[ads[0]].active = active == 0 ? 1 : 0;
            this.toggleToast(
              true,
              this.$t('ads.operation_success', { title, verb: active ? this.$t('ads.paused') : this.$t('ads.activated') }),
              7000,
              false
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('ads.operation_failed', { title, verb: this.$t('general.'+(active ? 'pause' : 'activate')).toLowerCase() }) : 
              _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');
            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.loader[campaign].ads[ad].loading = false;
          }
        )
      },

      removeAd (data) {
        const campaign = data.campaign;
        const ad = data.ad;
        const title = this.campaigns[campaign].ads[ad].title;

        this.loader[campaign].ads[ad].loading = true;
        this.ApiRemoveAd(
          this.user.auth.token,
          campaign,
          ad,
          (campaign, ad) => { 
            this.closeCampaignView();
            setTimeout(($) => {
              $.toggleToast(
                true,
                this.$t('ads.operation_success', { title, verb: this.$t('ads.removed') }),
                7000,
                false
              );
              $.$delete(this.campaigns[campaign].ads, ad);
            }, 250, this);
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('ads.operation_failed', { title, verb: this.$t('general.remove').toLowerCase() }) : 
              _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');
            
            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.loader[campaign].ads[ad].loading = false;
          }
        )
      },

      createAd (data) {
        const campaign = data.campaign;
        const ad = data.id;
        const title = data.title;
        data = this.processAdData(data);

        this.loader[campaign].ads[ad].loading = true;
        this.ApiCreateAd(
          this.user.auth.token,
          campaign,
          data,
          (campaign, ad, data) => { 
            this.$set(this.loader[campaign].ads, ad, {
              loading: false,
              updated: true,
              error: 0
            })
            this.$set(this.campaigns[campaign].ads, ad, data);
            this.selectAd({ campaign, ad });
            this.getCustoms(this.brand, true);
            setTimeout(($) => {
              $.$delete($.campaigns[campaign].ads, 'new');
            }, 500, this);
            if (this.campaigns[campaign].approval==2||this.campaigns[campaign].approval==1) {
              this.campaigns[campaign].approval = null;
            }
            this.toggleToast(
              true,
              this.$t('ads.operation_success', { title, verb: this.$t('ads.created') }),
              7000,
              false
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('ads.operation_failed', { title, verb: this.$t('general.create').toLowerCase() }) : 
              _.has(error.response.data, 'message') ? _.isArray(error.response.data.message) ? _.join(error.response.data.message, ', ') : error.response.data.message : this.$t('errors.generic');
            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.loader[campaign].ads[ad].loading = false;
          }
        )
      },

      updateAd (data) {
        const campaign = data.campaign;
        const ad = data.id;
        const title = _.has(data, 'title') ? data.title : this.campaigns[campaign].ads[ad].title;
        data = this.processAdData(data);

        this.loader[campaign].ads[ad].loading = true;
        this.ApiUpdateAd(
          this.user.auth.token,
          campaign,
          ad,
          data,
          (campaign, ad, data, approval) => { 
            this.$set(this.campaigns[campaign].ads, ad, data);
            this.campaigns[campaign].approval = approval;
            this.getCustoms(this.brand, true);
            this.toggleToast(
              true,
              this.$t('ads.operation_success', { title, verb: this.$t('ads.updated') }),
              7000,
              false
            );
          },
          (error) => { 
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              this.$t('ads.operation_failed', { title, verb: this.$t('general.update').toLowerCase() }) : 
              _.has(error.response.data, 'message') ? _.isArray(error.response.data.message) ? _.join(error.response.data.message, ', ') : error.response.data.message : this.$t('errors.generic');
            this.toggleToast(
              true,
              msg,
              15000,
              true
            );
          },
          () => { 
            this.loader[campaign].ads[ad].loading = false;
          }
        )
      },

       validateMedia (media) {
        this.view.tester.validation.message = null
        this.view.tester.validation.success = null
        this.view.tester.validation.loading = true
        this.ApiValidateMedia(
          this.user.auth.token,
          media,
          (info) => {          
            this.view.tester.validation.message = this.$t('ads.media_validated')
            this.view.tester.validation.success = true
          },
          (error) => { 
            if (_.has(error.response, 'status') && (error.response.status==406)) {
              const message = _.has(error.response.data, 'message') ? error.response.data.message : this.$t('ads.invalid_media')
              const info = _.has(error.response.data, 'info') ? error.response.data.info : null
              this.view.tester.validation.message = message
              this.view.tester.validation.success = false
            } else {
              const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
                this.$t('errors.unknown') : 
                _.has(error.response.data, 'message') ? error.response.data.message : this.$t('errors.generic');
              this.toggleToast(
                true,
                msg,
                15000,
                true
              );
            }
          },
          () => { 
            this.view.tester.validation.loading = false
          }
        )
      },

      getCustoms (brand, refresh=false) {
        const cities = this.brands[brand].cities;

        this.ApiGetCustoms(
          this.user.auth.token,
          brand,
          cities,
          (data) => { 
            brand = this.brands[brand];
            data = _.map(data, poi => {
              // console.log('poi', poi);
              return {
                id: poi.custom_id,
                title: poi.name,
                type: poi.type_code,
                use: poi.use_code,
                position: { lat: poi.lat, lng: poi.log },
                radius: _.has(poi, 'radius') ? poi.radius : null,
                url: _.has(poi, 'url')&&!!poi.url ? poi.url + (refresh ? '?k='+this.$moment().valueOf() : '') : null,
                local_id: poi.city_id,
                city: _.has(poi, 'city') ? poi.city : {
                  id: poi.city_id,
                  title: 'Rio de Janeiro'
                }
              }
            }),
            brand.pois = data;
            this.brands = Object.assign(this.brands, { [brand.id]: brand });
          },
        )
      },

      processCampaignData (data) {
        return _.reduce(data, (fields, field, f) => {
          switch (f) {
            case 'period':
              let period = {}
              if (this.$moment().isSameOrBefore(field.start, 'day')) period['start_date'] = this.$moment(field.start, 'YYYY-MM-DD').utc().format('YYYY-MM-DD HH:mm:ss');
              period['end_date'] = this.$moment(field.end, 'YYYY-MM-DD').add(1,'d').subtract(1,'s').utc().format('YYYY-MM-DD HH:mm:ss');
              fields = {
                ...period,
                ...fields
              }
              break;
            case 'title':
              fields = {
                campaign_name: field,
                ...fields
              }
              break;
            case 'children':
              fields = {
                ad_groups: field,
                ...fields
              }
              break;
            case 'pois':
              fields = {
                pois: _.isEmpty(field) ? null : _.map(field, p => `0_6_${p}_3`),
                ...fields
              }
              break;
            case 'contract':
              fields = {
                contract_id: field,
                ...fields
              }
              break;
            case 'cities':
              fields = {
                cities: field==null||_.isEmpty(field) ? null : JSON.stringify(_.map(field, city => {
                  return {
                    id_cidade: city.id,
                    dec_perc: city.weight,
                  }
                })),
                ...fields
              }
              break;
            default:
              fields = { 
                [f]: field, 
                ...fields 
              }
          }
          return fields;
        }, { invoice_id: null, payment_platform: 'STRIPE' });
      },

      processAdData (data) {
        return _.reduce(data, (fields, field, f) => {
          switch (f) {
            case 'period':
              fields = {
                start_date: this.$moment(field.date.start, 'YYYY-MM-DD').utc().format('YYYY-MM-DD HH:mm:ss'),
                end_date: this.$moment(field.date.end, 'YYYY-MM-DD').endOf('day').subtract(1,'s').utc().format('YYYY-MM-DD HH:mm:ss'),
                format: field.hours.format,
                reg_exp: field.hours.regex,
                ...fields
              }
              break;
            case 'media':
              if (field.url!=null) { 
                fields = {
                  media_name: field.name, 
                  duration: field.duration,
                  format_media: field.format,
                  ...fields
                }
                if (/^data:/.test(field.url)) {
                  fields['base64_media'] = field.url;
                }else{
                  fields['media_url'] = field.url;
                }
              }
              break
            case 'location':
              fields = {
                locals: _.isEmpty(field.geofences) ? '[]' : field.geofences,
                geofences: JSON.stringify(_.map(_.clone(field.pois), poi => {
                  return {
                    geofence_id: poi.id,
                    geofence_name: poi.title,
                    cod_type: poi.type,
                    cod_use: poi.use,
                    local_id: poi.local_id,
                    gmaps: _.has(poi, 'position') ? poi.position : null,
                    radius: _.has(poi, 'radius') ? poi.radius : null,
                    geojson: _.has(poi, 'geojson') ? poi.geojson : null,
                    geojson_url: _.has(poi, 'url') ? poi.url : null,
                    city: _.has(poi, 'city') ? poi.city : null,
                  }
                })),
                ...fields
              }
              break
            case 'weather':
              fields = {
                temperature: field,
                ...fields
              }
              break
            case 'title':
              fields = { 
                ad_name: field,
                ...fields
              }
              break
            default:
              fields = { 
                [f]: field,
                ...fields
              }
          }
          return fields;
        }, {});
      },

      updateView (refresh, to, from) {
        if (!_.isNil(to)) {
          const ad = to.params.ad;
          const campaign = to.params.campaign;
          this.view.ad = _.isNil(ad) ? null : ad;
          this.view.campaign = _.isNil(campaign) ? null : campaign;
          console.log('updateView', this.view.campaign)
        }

        if (refresh) {
          this.filters.status.suggested = false;
          this.updated = false;
          this.loadCampaigns();
        }
      },

      selectCampaign (campaign) {
        setTimeout(() => {
          this.$router.push({
            name: 'Campanha',
            params: {
              brand: this.$route.params.brand,
              campaign
            }
          }).catch((error) => {
            console.warn(error);
          });
        }, 150);
      },

      closeCampaignView () {
        const selected = _.clone(this.$route.params);
        setTimeout(($, selected) => {
          this.$router.push({
            name: 'Campanhas',
            params: {
              brand: this.$route.params.brand
            }
          }).then(() => {
            if (_.has($.campaigns, selected.campaign)&&_.has($.campaigns[selected.campaign].ads, 'new')) {
              $.$delete($.campaigns[selected.campaign].ads, 'new');
              $.$nextTick(() => {
                $.$delete($.loader[selected.campaign].ads, 'new');
              })
            }
            if (_.has($.campaigns, 'new')) {
              $.$delete($.campaigns, 'new');
              $.$delete($.loader, 'new');
            }
          }).catch((error) => {
            console.warn(error)
          });
          // this.filters.status.value = this.suggestStatus();
        }, 150, this, selected);
      },

      selectAd (selected) {
        console.log(selected)
        const params = _.assign(this.$route.params, {
          campaign: selected.campaign,
          ad: selected.ad,
        });
        setTimeout(() => {
          this.$router.push({
            name: 'Anúncio',
            params
          }).catch((error) => {
            console.warn(error)
          });
        }, 150);
      },

      setNewCampaign () {
        console.log('set new campaign');
        this.$set(this.loader, 'new', {
          ads: {},
          refresh: false,
          loading: false,
          analytics: false,
          loading: false,
          updated: true,
          error: 0
        })
        const brand = this.brands[this.brand];
        this.$set(this.campaigns, 'new', {
          id: 'new',
          brand: {
            id: brand.id,
            advertiser: null,
          },
          title: brand.title + ' ' + this.capitalize(this.$moment().format('MMM/YY')),
          impressions: 0,
          budget: 0,
          contract: null,
          cities: null,
          paid: 2,
          visible: 1,
          active: 2, 
          approval: null,
          period: {
            start: this.$moment().format('YYYY-MM-DD').toString(),
            end: this.$moment().add(30, 'days').format('YYYY-MM-DD').toString(),
          },
          duration: 30,
          airing: false,
          children: [],
          bonus: false,
          pois: [],
          ads: {}
        });

        this.selectCampaign('new');
      },

      setNewAd ({ campaign, ad=null }) {
        campaign = this.campaigns[campaign];
        // ad = _.has(campaign.ads, ad) ? _.clone(campaign.ads[ad]) : null;

        console.log('set new ad', ad);
        
        this.$set(this.loader[campaign.id].ads, 'new', {
          loading: false,
          updated: true,
          error: 0
        })
        this.$set(campaign.ads, 'new', _.isNil(ad) ? {
          id: 'new',
          active: 2,
          title: campaign.title + ' ' + (_.size(campaign.ads)+1),
          location: {
            geofences: [],
            poi: [],
          },
          media: {
            duration: 8,
            url: null,
            id: null,
            name: null
          },
          period: {
            start: this.$moment().isAfter(campaign.period.start) ? this.$moment().format('YYYY-MM-DD') : campaign.period.start,
            end: campaign.period.end
          },
          recurrence: {
            format: '%W %k',
            regex: '(^(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday) (0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21|22|23)$)'
          },
          temperature: '',
          vehicles: 0
        } : _.merge(_.cloneDeep(ad), { 
            id: 'new', 
            active: 2,
            title: ad.title + ' (1)',
          }));

        this.$nextTick(() => {
          this.selectAd({ campaign: campaign.id, ad: 'new' });
        });
      },

      openDashboard ({ campaign, group }) {
        console.log('dashboard', campaign, group);
        group = _.isNil(group) ? this.campaigns[campaign].children.length > 0 ? { groups: _.join(this.campaigns[campaign].children, ',') } : {} : { groups: group };
        const params = _.assign(this.$route.params, {
          campaign: campaign,
          period: _.join(_.values(this.campaigns[campaign].period), ',')
        }, group);
        setTimeout(() => {
          this.$router.push({
            name: 'Overview',
            params
          }).catch((error) => {
            console.warn(error)
          });
        }, 150);
      },

      groupedIn (id) {
        const g = _.find(this.campaigns, c => {
          return _.indexOf(c.children, id)>=0;
        });
        return _.isNil(g) ? null : g.id;
      },
    },

    mounted () {
      this.updateView(true, this.$route);
    },
  }
</script>
